<template>
  <div class="careers pt-4">
    <h5 class="letter-spacing-2">CAREERS</h5>
    <div class="container">
      <div class="row">
        <div class="col-12">
            <div class="mt-5">
              <p class="mb-0">No careers at the moment</p>
              <p class="mb-0">We will make sure to update this site with new opportunities</p>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Careers'
}
</script>

<style lang="scss">

</style>